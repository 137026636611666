<template>
	<v-container fluid>
		<v-row justify="center" >
			<v-col cols="12" md="8">
        
				<v-snackbar v-model="alerta.activo" multi-line vertical top right :color="alerta.color" > 
					<strong> {{alerta.texto}} </strong>
					<template v-slot:action="{ attrs }">
						<v-btn dark text @click="alerta.activo = false" v-bind="attrs"> Cerrar </v-btn>
					</template>
				</v-snackbar>

      	<v-btn fab fixed bottom left  color="primary" onClick="history.go(-1);"  > <v-icon >mdi-arrow-left-thick</v-icon> </v-btn>

        <v-toolbar color="primary" dark class="shadowCard">
          <div class="font-weight-black text-h6"> DETALLE DE LA CUENTA </div>
        </v-toolbar>

				<v-card class="shadowCard pa-3 mt-2">
					<v-row class="mt-3">
						<v-col cols="12" sm="6">
							<v-text-field
								append-icon="person"
								label="Nombres"
								placeholder="Nombres"
								hide-details
								clearable
								v-model ="usuarioFetish.nombre"
								outlined 
							></v-text-field>
						</v-col>

						<v-col cols="12" sm="6" >
							<v-text-field
								append-icon="person"
								label="Apellidos"
								placeholder="Apellidos"
								hide-details
								clearable
								v-model ="usuarioFetish.apellido"
								outlined 
							></v-text-field>
						</v-col>

						<v-col cols="12" sm="6" >
							<v-text-field
								append-icon="mdi-eye"
								label="Nombre visible"
								placeholder="Nombre Visible "
								hide-details
								clearable
								v-model ="usuarioFetish.usuario"
								outlined 
							></v-text-field>
						</v-col>

						<v-col cols="12" sm="6" >
							<v-text-field
								append-icon="email" 
								label="Correo electronico"
								placeholder="Correo electronico"
								hide-details
								clearable
								v-model="usuarioFetish.email"
								outlined 
							></v-text-field>
						</v-col>

						<v-col cols="12" sm="6" >
							<v-text-field
								append-icon="phone" 
								label="Telefono"
								placeholder="Telefono"
								hide-details
								clearable
								v-model="usuarioFetish.telefono"
								outlined 
								dark
								type="number"
							></v-text-field>
						</v-col>
						<!-- offset-sm="6"  -->
						<v-col cols="12" sm="6"   class="text-right  "> <!-- BOTON PARA MOSTRAR CONTRASEÑAS -->
							<v-btn  text color="info" dark  @click="cambiaPassword = !cambiaPassword" v-if="cambiaPassword"> Cambiar Contraseña	</v-btn>
							<v-btn  text color="error" dark @click="cambiaPassword = !cambiaPassword" v-else > Cancelar </v-btn>
						</v-col>

						<template v-if="!cambiaPassword" class="py-0">
							<v-col cols="12" sm="6">
								<v-text-field
									v-model="password"
									:append-icon="show1 ? 'visibility_off' : 'visibility'"
									:type="show1 ? 'text' : 'password'"
									placeholder="Contraseña de usuario"
									label="Contraseña"
									filled
									
									clearable
									hide-details
									@click:append="show1 = !show1"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6">
								<v-text-field
									v-model="password2"
									:append-icon="show2 ? 'visibility_off' : 'visibility'"
									:type="show2 ? 'text' : 'password'"
									label=" Confirmar Contraseña"
									placeholder="Confirmar Contraseña "
									filled
									clearable
									hide-details
									@click:append="show2 = !show2"
								></v-text-field>
							</v-col>
						</template>
					</v-row>
				</v-card>

				<!-- //DI{ALOG PARA GUARDAR LA INFORMACION -->
				<div class="mt-5"></div>
				<v-card-actions class="pa-0" >		
					<v-spacer></v-spacer>
					 <v-btn  :disabled="overlay" persistent :loading="overlay" dark large  color="secondary" @click="validaInfo">
             Guardar Información  
          </v-btn>
				</v-card-actions>
			</v-col>
		</v-row>
		<overlay v-if="overlay"/>
	</v-container>
	
</template>

<script>
	// import  SelectMixin from '@/mixins/SelectMixin.js';
	import {mapGetters, mapActions} from 'vuex'
	import overlay         from '@/components/overlay.vue'
	var md5 = require('md5'); 
	
	export default {
		// mixins:[SelectMixin],
	  components: {
			overlay,
		},
		props:[
			'param',
			'edit',
	  ],
	  data () {
			return {
				// VARIABLES PARA CONTRASEÑA
				cambiaPassword: true,
				password			:'',
				password2			:'',
				PasswordAEdit	:'',
				PasswordActual: '',
				show1					: false,
				show2					: false,
				// VARIABLES PRINCIPALES
				id_usuario:  null,
        usuario   : '',
				nombre		: '',
        apellidos : '',
				correo		: '',
				
				// ALERTAS
        alerta: { activo: false, texto:'', color:'error', vertical:true },
				overlay    : false,
				correcto   : true,
				// BOTON DE BORRAR
				borrarModal: false,
        usuarioFetish: [],
			}
		},
		
		created(){
      this.init();
		},
			
		computed:{
			// IMPORTANDO USO DE VUEX - CLIENTES (GETTERS)
      ...mapGetters('login',['getusuarioFetish']),

		},
		watch:{},

		methods:{

      init(){
        this.usuarioFetish = this.getusuarioFetish;
        this.PasswordActual = this.usuarioFetish.password;
      },

			validaInfo(){
				if(!this.usuarioFetish.usuario)	{ this.alerta = { activo: true, texto:'NO PUEDES OMITIR EL NOMBRE VISIBLE'   , color:'error'} ; return };
				if(!this.usuarioFetish.email)	{ this.alerta = { activo: true, texto:'NO PUEDES OMITIR EL CORREO ELECTRONIO', color:'error'} ; return };
				
        if(!this.cambiaPassword){
          if(this.password.localeCompare(this.password2) ){ // COMPARO LAS CONTRASEÑAS => TIENEN QUE SER IGUAL
            this.alerta = { activo: true, texto:'LAS CONTRASEÑAS NO COINCIDEN' , color:'error'}; return;
          }else if(this.password){
            this.PasswordAEdit = md5(this.password) // ASIGNO LA NUEVA CONTRASEÑA A VARIABLE PARA GRABAR
            this.ActualizarUsuario() // MANDO A PREPARAR EL OBJETO.
          }
        }else{
          this.PasswordAEdit = this.PasswordActual // SI NO SE MODIFICO LA CONTRASEÑA CONSERVO LA ACTUAL
          this.ActualizarUsuario() // MANDO A PREPARAR EL OBJETO.
        }
			},
			
			ActualizarUsuario(){
				const payload = new Object({
					data: this.usuarioFetish,
					password: this.PasswordAEdit
				})
				this.overlay = true;								
				this.$http.post('clientes.update', payload).then((response)=>{
					this.alerta = { activo: true, texto: response.body.message , color:'success'}
				}).catch(error =>{
					// console.log('error',error)
					 this.alerta = { activo: true, texto: error.body.message , color:'error'}
				}).finally(()=>{ this.overlay = false })
			},

			
		}
	}
</script>